import React, { useCallback } from 'react';
import { TaskCardProps } from '@/types/dashboard';
import { statusColorMap, statusToId } from "@/enums/dashboard-enum";

const TaskCard: React.FC<TaskCardProps> = ({
  label,
  count,
  color,
  fontColor,
  isSelected = label === 'All Studies',
  onClick
}) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      const statusId = statusToId[label as keyof typeof statusToId] || '';
      // Immediately trigger the status filter
      onClick(label, statusId);
    }
  }, [onClick, label]);

  // Determine the ring color based on the label
  const ringColor = isSelected && statusColorMap[label as keyof typeof statusColorMap]
    ? statusColorMap[label as keyof typeof statusColorMap]
    : '';

  return (
    <div
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
      className={`p-4 rounded-2xl cursor-pointer transition-all text-left border-2 ${isSelected ? ringColor : 'border-transparent'} ${color}`}
      role="tab"
      aria-selected={isSelected}
      aria-label={`Filter by ${label}`}
      tabIndex={0}
    >
      <span className={`text-2xl font-bold block ${fontColor}`}>
        {count.toString().padStart(2, '0')}
      </span>
      <span className={`text-base font-bold ${fontColor} capitalize`}>
        {label}
      </span>
    </div>
  );
};

export default React.memo(TaskCard); 