import { cn } from "@/lib/utils";

export default function Lock({ className = ''}) {
    return (
        <svg
            className={cn(
                className
            )}
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.0625 8V8.0625H16.125H19.4999C19.8811 8.06294 20.2465 8.21453 20.516 8.48404C20.7855 8.75354 20.9371 9.11894 20.9375 9.50007V19.9999C20.9371 20.3811 20.7855 20.7465 20.516 21.016C20.2465 21.2855 19.8811 21.4371 19.4999 21.4375H4.50007C4.11894 21.4371 3.75354 21.2855 3.48404 21.016C3.21453 20.7465 3.06294 20.3811 3.0625 19.9999L3.0625 9.50004C3.06295 9.11892 3.21454 8.75353 3.48404 8.48404C3.75354 8.21453 4.11894 8.06294 4.50007 8.0625H7.875H7.9375V8V5.375C7.9375 4.29756 8.36551 3.26425 9.12738 2.50238C9.88925 1.74051 10.9226 1.3125 12 1.3125C13.0774 1.3125 14.1108 1.74051 14.8726 2.50238C15.6345 3.26425 16.0625 4.29756 16.0625 5.375V8ZM14.625 8.0625H14.6875V8V5.375C14.6875 4.66223 14.4044 3.97865 13.9003 3.47465C13.3963 2.97065 12.7128 2.6875 12 2.6875C11.2872 2.6875 10.6037 2.97065 10.0997 3.47465C9.59565 3.97865 9.3125 4.66223 9.3125 5.375V8V8.0625H9.375H14.625ZM11.3397 15.7374C11.535 15.8679 11.7646 15.9375 11.9995 15.9375C12.1554 15.9375 12.3098 15.9068 12.4539 15.8471C12.598 15.7874 12.7289 15.7 12.8391 15.5897C12.9494 15.4794 13.0369 15.3485 13.0966 15.2044C13.1562 15.0604 13.187 14.9059 13.187 14.75C13.187 14.5151 13.1173 14.2855 12.9868 14.0903C12.8563 13.895 12.6709 13.7428 12.4539 13.6529C12.2369 13.563 11.9981 13.5395 11.7678 13.5853C11.5374 13.6311 11.3258 13.7442 11.1598 13.9103C10.9937 14.0764 10.8806 14.288 10.8348 14.5183C10.7889 14.7487 10.8125 14.9874 10.9023 15.2044C10.9922 15.4214 11.1444 15.6069 11.3397 15.7374Z"
                fill="#ACB6BF"
                stroke="#ACB6BF"
                strokeWidth="0.125"
            />
        </svg>
    );
}
