import React, { useState, useEffect, useRef } from 'react';
import BracketsAngle from '@/components/svg/BracketsAngle';
import { ArrowDown, ArrowUpIcon } from 'lucide-react';
import { SortableHeaderProps } from '@/types/dashboard';
import { cn } from '@/lib/utils';

const SortableHeader: React.FC<SortableHeaderProps> = ({ title, onSort, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDateTimeColumn = title === "SCHEDULED DATE & TIME";
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className={cn("relative inline-block", className)} ref={dropdownRef}>
      <button 
        className={`flex items-center gap-1 ${isOpen ? 'bg-header-tabsStatic p-[3px] pl-[3px] rounded border border-builder-defaultBorder' : ''}`} 
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
          {title}
        <BracketsAngle className="m-1 h-3 w-2 stroke-builder-lightText" />
      </button>
      {isOpen && (
        <ul
          className="absolute top-full left-0 mt-1 w-48  shadow-lg rounded-md z-[1000] text-builder-lightText border"
        >
          <li>
            <button
              className="flex items-center gap-1 w-full text-xs text-left p-3 bg-builder-background rounded-t-md"
              onClick={() => {
                onSort(false);
                setIsOpen(false);
              }}
            >
              <ArrowUpIcon className="h-3 w-3" />
              {isDateTimeColumn ? "Sort Newest" : "Sort Ascending"}
            </button>
          </li>
          <li className="border-t bg-header-borderLine" />
          <li>
            <button
              className="flex items-center gap-1 w-full text-xs text-left p-3 bg-builder-background rounded-b-md"
              onClick={() => {
                onSort(true);
                setIsOpen(false);
              }}
            >
              <ArrowDown className="h-3 w-3" />
              {isDateTimeColumn ? "Sort Oldest" : "Sort Descending"}
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default SortableHeader; 