import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useExamStore } from './use-exam-store';

interface ViewState {
  rotation: number;
  flipHorizontal: boolean;
  flipVertical: boolean;
}

type ActionType = 'MEASUREMENT' | 'VIEW_TRANSFORM';

interface HistoryState {
  annotations: any[];
  viewState: ViewState;
  actionType: ActionType;
}

interface AnnotationHistoryState {
  undoStack: HistoryState[];
  redoStack: HistoryState[];
  currentAnnotations: any[];
  currentViewState: ViewState;
  imageAnnotations: Record<string, any[]>;
  historyStacks: Record<string, {
    undoStack: HistoryState[];
    redoStack: HistoryState[];
  }>;
  pushToHistory: (annotations: any[], viewState: ViewState, actionType: ActionType) => void;
  undo: () => HistoryState | null;
  redo: () => HistoryState | null;
  clearHistory: () => void;
  setCurrentAnnotations: (annotations: any[]) => void;
  setCurrentViewState: (viewState: ViewState) => void;
  canUndo: () => boolean;
  canRedo: () => boolean;
  setImageAnnotations: (imageAnnotations: Record<string, any[]>) => void;
  clearAllHistory: () => void;
}

const DEFAULT_VIEW_STATE: ViewState = {
  rotation: 0,
  flipHorizontal: false,
  flipVertical: false
};

const EMPTY_HISTORY: { undoStack: HistoryState[]; redoStack: HistoryState[] } = {
  undoStack: [],
  redoStack: []
};

const safeJSONParse = <T>(data: T): T => JSON.parse(JSON.stringify(data));

const storage = {
  getItem: (key: string) => {
    try {
      if (typeof window === 'undefined') return null;
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch {
      return null;
    }
  },
  setItem: (key: string, value: unknown) => {
    try {
      if (typeof window === 'undefined') return;
      localStorage.setItem(key, JSON.stringify(value));
    } catch {
      // Silent fail for storage errors
    }
  },
  removeItem: (key: string) => {
    try {
      if (typeof window === 'undefined') return;
      localStorage.removeItem(key);
    } catch {
      // Silent fail for storage errors
    }
  }
};

const getCurrentThumbnailId = (): string => {
  const currentThumbnailId = useExamStore.getState().getCurrentThumbnail();
  return currentThumbnailId?.toString() ?? '';
};

const updateHistoryStack = (
  currentStack: { undoStack: HistoryState[]; redoStack: HistoryState[]; },
  sourceStack: HistoryState[],
  targetStack: HistoryState[],
  currentState: { annotations: any[]; viewState: ViewState; },
  nextState: HistoryState
): typeof EMPTY_HISTORY => {
  const isUndo = sourceStack === currentStack.undoStack;
  const newState = {
    annotations: safeJSONParse(currentState.annotations),
    viewState: currentState.viewState,
    actionType: nextState.actionType
  };

  return {
    undoStack: isUndo ? sourceStack.slice(0, -1) : [...targetStack, newState],
    redoStack: isUndo ? [...targetStack, newState] : sourceStack.slice(0, -1)
  };
};

const createHistoryOperation = (
  get: () => AnnotationHistoryState,
  set: (fn: (state: AnnotationHistoryState) => Partial<AnnotationHistoryState>) => void,
  isUndo: boolean
) => {
  const thumbnailId = getCurrentThumbnailId();
  const state = get();
  const currentStack = state.historyStacks[thumbnailId];
  const sourceStack = isUndo ? currentStack?.undoStack : currentStack?.redoStack;
  
  if (!sourceStack?.length) return null;
  
  const nextState = sourceStack[sourceStack.length - 1];
  const targetStack = isUndo ? currentStack.redoStack : currentStack.undoStack;
  
  set((state) => ({
    historyStacks: {
      ...state.historyStacks,
      [thumbnailId]: updateHistoryStack(
        currentStack,
        sourceStack,
        targetStack,
        { annotations: state.currentAnnotations, viewState: state.currentViewState },
        nextState
      )
    },
    currentAnnotations: safeJSONParse(nextState.annotations),
    currentViewState: nextState.viewState,
  }));

  return nextState;
};

export const useAnnotationHistoryStore = create<AnnotationHistoryState>()(
  persist(
    (set, get) => ({
      ...EMPTY_HISTORY,
      currentAnnotations: [],
      currentViewState: DEFAULT_VIEW_STATE,
      imageAnnotations: {},
      historyStacks: {},

      pushToHistory: (annotations: any[], viewState: ViewState, actionType: ActionType) => {
        const thumbnailId = getCurrentThumbnailId();
        set((state) => {
          const currentStack = state.historyStacks[thumbnailId] || EMPTY_HISTORY;
          return {
            historyStacks: {
              ...state.historyStacks,
              [thumbnailId]: {
                undoStack: [...currentStack.undoStack, {
                  annotations: safeJSONParse(state.currentAnnotations),
                  viewState: state.currentViewState,
                  actionType
                }],
                redoStack: []
              }
            },
            currentAnnotations: safeJSONParse(annotations),
            currentViewState: viewState,
          };
        });
      },

      undo: () => createHistoryOperation(get, set, true),
      redo: () => createHistoryOperation(get, set, false),

      clearHistory: () => {
        const thumbnailId = getCurrentThumbnailId();
        set((state) => {
          const { [thumbnailId]: _, ...newImageAnnotations } = state.imageAnnotations;
          const { [thumbnailId]: __, ...newHistoryStacks } = state.historyStacks;
          
          return {
            historyStacks: newHistoryStacks,
            currentAnnotations: [],
            currentViewState: DEFAULT_VIEW_STATE,
            imageAnnotations: newImageAnnotations,
          };
        });
      },

      canUndo: () => {
        const { historyStacks } = get();
        const currentStack = historyStacks[getCurrentThumbnailId()];
        return Boolean(currentStack?.undoStack?.length);
      },

      canRedo: () => {
        const { historyStacks } = get();
        const currentStack = historyStacks[getCurrentThumbnailId()];
        return Boolean(currentStack?.redoStack?.length);
      },

      setCurrentAnnotations: (annotations: any[]) => {
        const thumbnailId = getCurrentThumbnailId();
        set((state) => ({
          currentAnnotations: safeJSONParse(annotations),
          imageAnnotations: { 
            ...state.imageAnnotations, 
            [thumbnailId]: safeJSONParse(annotations)
          },
        }));
      },

      setCurrentViewState: (viewState: ViewState) => {
        set({ currentViewState: viewState });
      },

      setImageAnnotations: (imageAnnotations: Record<string, any[]>) => {
        set({ imageAnnotations });
      },

      clearAllHistory: () => {
        set({
          ...EMPTY_HISTORY,
          currentAnnotations: [],
          currentViewState: DEFAULT_VIEW_STATE,
          imageAnnotations: {},
          historyStacks: {}
        });
      }
    }),
    {
      name: 'annotation-history-storage',
      storage,
    }
  )
); 