import React from 'react';
import { StatusCellProps } from '@/types/dashboard';

const StatusCell: React.FC<StatusCellProps> = ({ status, getStatusClasses }) => (
  <div className={`px-2 py-1 rounded-lg text-center font-medium uppercase ${getStatusClasses(status)} 
    text-[0.5rem] sm:text-[0.625rem] md:text-xs lg:text-sm
    w-full overflow-hidden text-ellipsis whitespace-nowrap`}>
    {status}
  </div>
);

export default StatusCell;