import React, { useRef, useCallback, memo, useState, useEffect } from "react";
import {
  Root as TooltipRoot,
  Trigger as TooltipTrigger,
  Provider as TooltipProvider,
  Content as TooltipContent,
} from "@radix-ui/react-tooltip";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
} from "@/components/ui/table";
import { flexRender, HeaderGroup, Row } from "@tanstack/react-table";
import { momentFormat } from "@/utlity/format";
import { Patient } from "@/types/Patient";
import { useInView } from "react-intersection-observer";
import ActionCell from "@/components/dashboard/ActionCell";
import ReportTemplateCell from "@/components/dashboard/ReportTemplateCell";
import { Status, TableRowWithTooltipProps, PatientTableProps, ReportTemplate, EExamStatusId } from "@/types/dashboard";
import { apiService } from "@/utlity/api-service";
import { API_LIST } from "@/utlity/api-list";
import { CustomAxiosResponse } from "@/types";

const CustomTooltip = memo(({ row, position }: { row: Row<Patient>; position: { x: number; y: number } }) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [adjustedPosition, setAdjustedPosition] = useState(position);

  useEffect(() => {
    const updateTooltipPosition = () => {
      const tooltip= tooltipRef.current;
      if (!tooltip) return;

      const tooltipRect: DOMRect = tooltip.getBoundingClientRect();
      const windowWidth: number = window.innerWidth;

      let newX: number = position.x;
      let newY: number = position.y;
      const padding:number = 10;

        if (tooltipRect.width > 0 && tooltipRect.height > 0) {
        const tooltipWidth: number = tooltipRect.width;
        if (newX - tooltipWidth / 2 < padding) {
          newX = tooltipWidth / 2 + padding;
        } else if (newX + tooltipWidth / 2 > windowWidth - padding) {
          newX = windowWidth - tooltipWidth / 2 - padding;
        }

        const tooltipHeight: number = tooltipRect.height + 10;
        if (newY - tooltipHeight < padding) {
          newY = position.y + 20;
          tooltip.style.transform = 'translate(-50%, 0)';
        } else {
          tooltip.style.transform = 'translate(-50%, -100%)';
        }

        setAdjustedPosition({ x: newX, y: newY });
      }
    };
    updateTooltipPosition();
  }, [position]);

  const isScheduled: boolean = row.original.statusId === EExamStatusId.SCHEDULED;

  return (
    <TooltipContent 
      ref={tooltipRef}
      sideOffset={5} 
      className="bg-builder-background rounded-md shadow-md border border-builder-defaultBorder p-4 min-w-[280px] fixed z-1000 pointer-events-none"
      style={{
        left: `${adjustedPosition.x}px`,
        top: `${adjustedPosition.y}px`,
      }}
    >
      <div className="space-y-1">
        <div className="text-xs">
          <span className="text-dropdown-primaryText font-bold">Images available: </span>
          <span className="text-dropdown-primaryText">{isScheduled ? "-" : row.original.clipboardImages}</span>
        </div>
        <div className="text-xs">
          <span className="text-dropdown-primaryText font-bold">Performed on: </span>
          <span className="text-dropdown-primaryText">{isScheduled ? "-" : momentFormat(row.original.scheduledDateTime, 'MMM D, YYYY, hh:mm A')}</span>
        </div>
        <div className="text-xs">
          <span className="text-dropdown-primaryText font-bold">Performed by: </span>
          <span className="text-dropdown-primaryText">{isScheduled ? "-" : row.original.readingPhysician}</span>
        </div>
      </div>
      <div className="absolute -bottom-2.5 left-1/2 -translate-x-1/2 w-5 h-2.5 overflow-hidden">
        <div className="absolute w-3.5 h-3.5 bg-builder-background border border-builder-defaultBorder -translate-x-1/2 -translate-y-1/2 rotate-45 top-0 left-1/2 shadow-[2px_2px_4px_rgba(0,0,0,0.1)]" />
      </div>
    </TooltipContent>
  );
});

const TableRowWithTooltip = memo(({ 
  row, 
  isSelected, 
  onSelect, 
  onBlur,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  handleActionClick,
  onTemplateChange,
  selectedTemplates,
  templates
}: TableRowWithTooltipProps) => {
  const currentTemplate = selectedTemplates[row.original.id] || row.original.reportTemplate;

  const handleCellClick = useCallback((e: React.MouseEvent, columnId: string) => {
    e.stopPropagation();
    
    if (columnId === 'reportTemplate' || columnId === 'action') {
      return;
    }
  }, []);

  const handleTemplateChange = useCallback((id: number, templateName: string) => {
    onTemplateChange(id, templateName);
  }, [onTemplateChange]);

  const handleActionButtonClick = useCallback((params: { id: number; status: Status; statusId: EExamStatusId }) => {
    handleActionClick(params);
  }, [handleActionClick]);

  return (
    <tr
      className={`border-b border-builder-defaultBorder text-builder-darkText hover:bg-builder-innerBackground cursor-pointer 
        ${isSelected ? 'bg-blue-100 hover:bg-blue-50 text-blue-600' : ''}`}
      style={{ height: '56px' }}
      onClick={onSelect}
      tabIndex={0}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      data-row-id={row.id}
    >
      {row.getVisibleCells().map((cell) => (
        <td 
          key={cell.id} 
          className={`p-2 text-base font-normal ${
            cell.column.id === 'status' || cell.column.id === 'action' ? 'text-center' : ''
          }`}
          style={{ 
            width: cell.column.getSize(),
            minWidth: cell.column.getSize(),
            maxWidth: cell.column.getSize(),
            verticalAlign: 'middle'
          }}
          onClick={(e) => handleCellClick(e, cell.column.id)}
          onMouseEnter={(e) => {
            if (cell.column.id === 'reportTemplate') {
              e.stopPropagation();
            }
          }}
          onMouseLeave={(e) => {
            if (cell.column.id === 'reportTemplate') {
              e.stopPropagation();
            }
          }}
          onMouseMove={(e) => {
            if (cell.column.id === 'reportTemplate') {
              e.stopPropagation();
            }
          }}
        >
          {(() => {
            if (cell.column.id === 'reportTemplate') {
              return (
                <ReportTemplateCell
                  id={row.original.id}
                  reportTemplate={row.original.reportTemplate}
                  selectedTemplates={selectedTemplates}
                  onTemplateChange={handleTemplateChange}
                  status={row.original.status as Status}
                  templates={templates}
                />
              );
            }
            if (cell.column.id === 'action') {
              return (
                <ActionCell
                  action=""
                  selectedTemplate={currentTemplate}
                  status={row.original.status as Status}
                  statusId={row.original.statusId}
                  id={row.original.id}
                  onClick={handleActionButtonClick}
                />
              );
            }
            return flexRender(cell.column.columnDef.cell, cell.getContext());
          })()}
        </td>
      ))}
    </tr>
  );
});

TableRowWithTooltip.displayName = 'TableRowWithTooltip';

const EmptyState = memo(({ colSpan }: { colSpan: number }) => (
  <TableRow>
    <td colSpan={colSpan} className="h-24 text-center text-sm">
      No results found.
    </td>
  </TableRow>
));

const LoadingSpinner = memo(() => (
  <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-gray-900" />
));

const ErrorState = memo(({ colSpan }: { colSpan: number }) => (
  <TableRow>
    <td colSpan={colSpan} className="h-24 text-center text-sm text-red-500">
      An error occurred while loading the data.
    </td>
  </TableRow>
));

const PatientTable = memo<PatientTableProps>(({
  table,
  isLoading = false,
  hasError = false,
  onBottomReached,
  hasNextPage,
  totalRecords,
  isFetching,
  handleActionClick,
  onTemplateChange = () => {},
  selectedTemplates = {}
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [templates, setTemplates] = useState<ReportTemplate[]>([]);

  const { ref: infiniteScrollRef, inView } = useInView({
    threshold: 0.1,
    rootMargin: '100px',
    triggerOnce: false,
  });

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response: CustomAxiosResponse = await apiService.get(API_LIST.GET_REPORT_TEMPLATES);
        if (response.status === 200) {
          setTemplates(response.data);
        }
      } catch (error) {
      }
    };

    fetchTemplates();
  }, []);

  const rows = table.getRowModel().rows;
  const filteredRows = React.useMemo(() => {
    const selectedStatus = table.getState().columnFilters.find(f => f.id === 'status')?.value;
    return selectedStatus 
      ? rows.filter(row => row.original.status === selectedStatus)
      : rows;
  }, [rows, table]);

  React.useEffect(() => {
    if (inView && !isFetching && hasNextPage) {
      onBottomReached();
    }
  }, [inView, isFetching, hasNextPage, onBottomReached]);

  const handleRowSelect = useCallback((rowId: string) => {
    setSelectedRowId(prev => prev === rowId ? null : rowId);
  }, []);

  const handleRowBlur = useCallback(() => {
    setSelectedRowId(null);
  }, []);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    const rect: DOMRect = e.currentTarget.getBoundingClientRect();
    const tableRect: DOMRect | undefined = tableContainerRef.current?.getBoundingClientRect();
    
    if (tableRect) {
        const x: number = Math.min(Math.max(e.clientX, tableRect.left), tableRect.right);      
        setTooltipPosition({
            x,
            y: rect.top - 10
        });
    }
  }, []);

  const handleRowHover = useCallback((rowId: string | null) => {
    setHoveredRowId(rowId);
    if (rowId) {
        const rowElement = document.querySelector(`[data-row-id="${rowId}"]`);
        if (rowElement) {
            const rect = rowElement.getBoundingClientRect();
            setTooltipPosition({
                x: rect.left + rect.width / 2,
                y: rect.top - 10 
            });
        }
    }
  }, []);

  const handleTemplateChange = useCallback((id: number, templateName: string) => {
    onTemplateChange(id, templateName);
  }, [onTemplateChange]);

  const handleActionButtonClick = useCallback((params: { id: number; status: Status; statusId: EExamStatusId }) => {
    handleActionClick(params);
  }, [handleActionClick]);

  const renderTableBody = useCallback(() => {
    if (hasError) {
      return <ErrorState colSpan={table.getAllColumns().length} />;
    }

    if ((isLoading) && !filteredRows.length) {
      return (
        <TableRow>
          <td colSpan={table.getAllColumns().length} className="h-96 text-center">
            <div className="flex justify-center items-center h-64">
              <LoadingSpinner />
            </div>
          </td>
        </TableRow>
      );
    }

    if (!filteredRows.length) {
      return <EmptyState colSpan={table.getAllColumns().length} />;
    }

    const handleSelect = (row: Row<Patient>) => {
      setSelectedRowId(row?.id === selectedRowId ? null : row?.id)
    }

    return (
      <>
        {filteredRows.map((row: Row<Patient>) => (
          <TableRowWithTooltip
            key={row.id}
            row={row}
            isSelected={row.id === selectedRowId}
            onSelect={() => handleSelect(row)}
            onBlur={handleRowBlur}
            onMouseEnter={() => handleRowHover(row.id)}
            onMouseLeave={() => handleRowHover(null)}
            onMouseMove={handleMouseMove}
            handleActionClick={handleActionButtonClick}
            onTemplateChange={handleTemplateChange}
            selectedTemplates={selectedTemplates}
            templates={templates}
          />
        ))}
        {hasNextPage && !table.getState().columnFilters.find(f => f.id === 'status')?.value && (
          <tr ref={infiniteScrollRef}>
            <td colSpan={table.getAllColumns().length} className="h-20 text-center">
              {(isLoading || isFetching) && (
                <div className="flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </td>
          </tr>
        )}
      </>
    );
  }, [
    table,
    isLoading,
    hasError,
    isFetching,
    filteredRows,
    infiniteScrollRef,
    hasNextPage,
    selectedRowId,
    handleMouseMove,
    handleActionButtonClick,
    handleTemplateChange,
    selectedTemplates,
    handleRowSelect,
    handleRowBlur,
    handleRowHover,
    templates,
  ]);

  return (
    <TooltipProvider delayDuration={300}>
      <div className="w-full border border-builder-defaultBorder rounded-lg">
        <div>  
          {/* Fixed Header */}
          <div className="sticky top-0 bg-[hsla(219,82%,44%,0.2)] border-b border-builder-defaultBorder">
            <div className="min-w-[800px]">
              <Table className="w-full">
                <TableHeader className="h-14">
                  <TableRow>
                    {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) =>
                      headerGroup.headers.map((header) => (
                        <TableHead 
                          key={header.id} 
                          className={`p-2 font-semibold text-builder-darkText ${
                            header.id === 'status' || header.id === 'action' ? 'text-center' : 'text-left'
                          }`}
                          style={{ 
                            width: header.getSize(),
                            minWidth: header.getSize(),
                            maxWidth: header.getSize(),
                            height: '56px',
                            verticalAlign: 'middle',
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      ))
                    )}
                  </TableRow>
                </TableHeader>
              </Table>
            </div>
          </div>
          <div 
            ref={tableContainerRef} 
            className="max-h-[calc(66vh-3.6rem)] overflow-y-auto static"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            <div className="min-w-[800px]">
              <Table className="w-full">
                <TableBody>
                  {renderTableBody()}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div id="tooltip-container" className="fixed top-0 left-0 pointer-events-none">
          {hoveredRowId && filteredRows.map((row: Row<Patient>) => (
            row.id === hoveredRowId && (
              <TooltipRoot key={`tooltip-${row.id}`} open={true}>
                <TooltipTrigger asChild>
                  <div className="hidden" />
                </TooltipTrigger>
                <CustomTooltip row={row} position={tooltipPosition} />
              </TooltipRoot>
            )
          ))}
        </div>
      </div>
    </TooltipProvider>
  );
});

PatientTable.displayName = 'PatientTable';

export default PatientTable;