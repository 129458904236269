import React, { useMemo } from 'react';
import Lock from '../svg/lock';
import { Status, ActionCellProps, EExamStatusId } from '@/types/dashboard';

const ActionCell: React.FC<ActionCellProps> = ({ action, status, onClick, id, selectedTemplate, statusId }) => {

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    if (isDisabled || !onClick) return;
    onClick({ id, status, statusId: statusId as EExamStatusId });
  };

  const isDisabled: boolean = useMemo(() => {
    const isTemplateRequired = status === Status.Preliminary;
    const hasTemplate = Boolean(selectedTemplate);
    const isFinalized = status === Status.Finalized;

    if (isTemplateRequired) {
      return !hasTemplate;
    }
    return isFinalized;
  }, [status, selectedTemplate]);

  const buttonStyle: string = useMemo(() => {
    return isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-blue-500 cursor-pointer hover:text-blue-600 transition-colors';
  }, [isDisabled]);

  const Component: React.ElementType = status === Status.Finalized ? Lock : 'span';

  return (
    <div className="flex items-center justify-center p-2">
      <Component
        className={buttonStyle}
        onClick={handleClick}
        role={Component === 'span' ? 'button' : undefined}
        aria-disabled={isDisabled}
        data-testid="action-cell-button"
      >
        {status === Status.Finalized ? null : 'Open'}
      </Component>
    </div>
  );
};

export default React.memo(ActionCell);