import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import FilterIcon from "@/components/svg/funnel";
import { Patient } from "@/types/Patient";
import { momentFormat } from "@/utlity/format";
import { FilterButtonProps, FilterItem, FilterOptions, Filters } from "@/types/dashboard";
import CaretRight from "../svg/caret-right";

const FilterButton: React.FC<FilterButtonProps> = ({
  patients,
  allPatients,
  onFilterChange,
  selectedStatus
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<FilterOptions>({
    examType: [],
    readingPhyId: [],
    date: []
  });
  const filterRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  /**
  * Generic function to extract unique values based on a key.
  * @param data - The array of objects.
  * @param key - The property key for uniqueness.
  * @param transform - A function to transform the structure.
  * @returns Unique values in the required format.
  */
  const getUniqueValues = <T, K extends keyof T>(
    data: T[],
    key: K,
    transform: (item: T) => FilterItem
  ): FilterItem[] => {
    const uniqueMap = new Map<string | number, FilterItem>();
    data.forEach((item) => {
      const value = item[key];
      if (value && !uniqueMap.has(value as string | number)) {
        uniqueMap.set(value as string | number, transform(item));
      }
    });
    return Array.from(uniqueMap.values());
  };

  /**
  * Hook to generate available filters from patient data.
  * @param patients - The list of patients.
  * @returns Filtered unique values.
  */
  const availableFilters = useMemo(() => {
    const dataSource = selectedStatus === 'All Studies' ? allPatients : patients;
    
    return {
      date: getUniqueValues<Patient, "scheduledDateTime">(
        dataSource,
        'scheduledDateTime',
        (patient) => ({
          label: momentFormat(patient.scheduledDateTime, 'MM/DD/YYYY'),
          value: momentFormat(patient.scheduledDateTime, 'MM/DD/YYYY'),
        })
      ),
      examType: getUniqueValues<Patient, "examTypeID">(
        dataSource,
        'examTypeID',
        (patient) => ({
          label: patient.examType,
          value: patient.examTypeID?.toString() ?? '',
        })
      ),
      readingPhyId: getUniqueValues<Patient, "readingPhysicianID">(
        dataSource,
        'readingPhysicianID',
        (patient) => ({
          label: patient.readingPhysician.toUpperCase(),
          value: patient.readingPhysicianID?.toString() ?? '',
        })
      ),
    };
  }, [allPatients, patients, selectedStatus]);

  // Check if date filter should be shown
  const showDateFilter: boolean = useMemo(() => availableFilters?.date?.length > 1, [availableFilters?.date]);

  const toggleFilterDropdown = useCallback(() => {
    setIsFilterOpen((prev) => !prev);
    setActiveDropdown(null);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsFilterOpen(false);
        setActiveDropdown(null);
      }
    };

    if (typeof window !== 'undefined') {
      document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  const handleFilterSelect = useCallback(
    (
      filterType: keyof FilterOptions,
      value: string | number | undefined  ,
      checked: boolean
    ) => {
      setSelectedFilters((prev) => {
        const updatedFilters = {
          ...prev,
          [filterType]: checked
            ? [...(prev[filterType] || []), value?.toString() ?? '']
            : (prev[filterType] || []).filter((item) => item !== (value?.toString() ?? ''))
        };
        return updatedFilters;
      });
    },
    []
  );


  const handleApplyFilters = useCallback(() => {
    // Transform FilterOptions to Filters format
    const transformedFilters: Filters = {
      date: selectedFilters.date || [],
      examType: selectedFilters.examType || [],
      readingPhyId: selectedFilters.readingPhyId || [] // Map readingPhyId to readingPhyId
    };

    onFilterChange(transformedFilters);
    setIsFilterOpen(false);
    setActiveDropdown(null);
  }, [onFilterChange, selectedFilters]);

  const clearAllFilters = useCallback((filterType: keyof FilterOptions) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterType]: []
    }));
  }, []);

  const renderSubDropdown = useCallback(
    (
      filterType: keyof FilterOptions,
      options: FilterItem[]
    ) => (
      <div
        className="absolute left-full top-0 w-60 rounded-t-md rounded-b-md  shadow-lg border border-builder-defaultBorder bg-builder-background text-builder-darkText text-xs z-20"
        onMouseEnter={() => setActiveDropdown(filterType)}
        onMouseLeave={() => setActiveDropdown(null)}
        role="menu"
        tabIndex={0}
      >
        {availableFilters[filterType].map((option: FilterItem) => (
          <div
            key={`${filterType}-${option.value}`}
            className="flex items-center w-full p-2 border-b border-builder-defaultBorder hover:bg-builder-innerBackground dark:hover:bg-icon-activeBg rounded-t-md"
            role="menuitem"
            tabIndex={0}
          >
            <div className="flex items-center w-full">
              <label htmlFor={`${filterType}-${option.value}`} className="flex items-center">
                <input
                  type="checkbox"
                  id={`${filterType}-${option.value}`}
                  className="mr-2 appearance-none h-4 w-4 border rounded-sm !bg-background checked:bg-red-500 checked:border-radio-displaySecText checked:focus:ring-0 relative cursor-pointer"
                  checked={selectedFilters[filterType]?.includes(option.value?.toString() ?? '')}
                  onChange={(e) => handleFilterSelect(filterType, option.value ?? '', e.target.checked)}
                  aria-label={`Filter by ${filterType}: ${option.label}`}
                />
                <span className="absolute ml-[1.5px] w-4 h-4 flex items-center justify-center">
                  {selectedFilters[filterType]?.includes(option.value?.toString() ?? '') && (
                    <svg
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      viewBox="0 0 0 2"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12l5 5L20 7" />
                    </svg>
                  )}
                </span>
              </label>
              <label
                htmlFor={`${filterType}-${option.value}`}
                className="flex-1 cursor-pointer"
              >
                {option.label}
              </label>
            </div>
          </div>
        ))}
        <div className="flex justify-end p-3 gap-3">
          <button 
            onClick={() => clearAllFilters(filterType)} 
            role="menuitem"
            disabled={!selectedFilters[filterType]?.length}
            className={`${!selectedFilters[filterType]?.length ? 'text-gray-400 cursor-not-allowed' : 'cursor-pointer'}`}
          >
            Clear all
          </button>
          <button onClick={handleApplyFilters} role="menuitem" className="text-blue-500">
            Done
          </button>
        </div>
      </div>
    ),
    [availableFilters, selectedFilters, clearAllFilters, handleApplyFilters, handleFilterSelect]
  );


  const getFilterState = useMemo(() => {
    const selectedCategories = [
      selectedFilters.date.length > 0,
      selectedFilters.examType.length > 0,
      selectedFilters.readingPhyId.length > 0,
    ];

    const selectedCount = selectedCategories.filter(Boolean).length; 

    const isApplied = selectedCount > 0;
    let buttonStyle = "border-gray-400 bg-white stroke-gray-400 dark:bg-background";  

    if (isFilterOpen || isApplied) {
      buttonStyle = "border-[1px] border-icon-activeBorder bg-blue-200 stroke-icon-activeBorder dark:bg-icon-activeBg"; 
    }
      
    let badgeStyle = "";
    if (isFilterOpen) {
      badgeStyle = "bg-red-700 text-gray-100";    
    } else if (isApplied) {
      badgeStyle = "bg-gray-400 text-gray-100 border-[1px] border-icon-activeBorder";   
    }

    return {
      selectedCount,
      isApplied,
      buttonStyle,
      badgeStyle,
      showBadge: isApplied
    };
  }, [selectedFilters, isFilterOpen]);

  return (
    <div className="relative">
      <button
        ref={filterRef}
        className={`relative  border-[1px] rounded-lg p-2 transition-colors ${getFilterState.buttonStyle}`}
        onClick={toggleFilterDropdown}
        aria-label="Open filter options"
        aria-haspopup="true"
        aria-expanded={isFilterOpen}
      >
   <FilterIcon className="h-5 w-5" />
        {getFilterState.showBadge && (
          <span className={`absolute bottom-6 -right-[8px] min-w-[22px] h-[22px] px-1 py-0.5 text-[11px] font-semi-bold rounded-full flex items-center justify-center ${getFilterState.badgeStyle}`}>
            {getFilterState.selectedCount}
          </span>
        )}
      </button>

      {isFilterOpen && (
        <div
          ref={dropdownRef}
          className="absolute -left-40 mt-2 w-48 rounded-lg shadow-xl bg-builder-background text-sm text-builder-darkText z-30 border -b transition-all duration-200 ease-in-out"
          onMouseEnter={() => setActiveDropdown(null)}
          role="menu"
          tabIndex={0}
          style={{ transform: activeDropdown ? 'translateX(-232px)' : 'translateX(0)' }}
        >
          {showDateFilter && (
          <div className="relative group">
            <p
              className="w-full text-left px-4 py-2 border-b border-builder-defaultBorder hover:text-blue-600 rounded-t-lg flex items-center justify-between hover:bg-icon-activeBg"
              onMouseEnter={() => setActiveDropdown("date")}
            >
              Date
              {activeDropdown === "date" && <CaretRight className="w-4 h-4 stroke-blue-500" />}
            </p>
            {activeDropdown === "date" && renderSubDropdown("date", availableFilters.date)}
          </div>
          )}

          <div className="relative group">
            <button
              className="w-full text-left px-4 py-2 border-b border-builder-defaultBorder hover:text-blue-600 flex items-center justify-between hover:bg-user-filterHoverBg"
              onMouseEnter={() => setActiveDropdown("examType")}
            >
              Exam Type
              {activeDropdown === "examType" && <CaretRight className="w-4 h-4 stroke-blue-500" />}
            </button>
            {activeDropdown === "examType" && renderSubDropdown("examType", availableFilters.examType)}
          </div>

          <div className="relative group">
            <button
              className="w-full text-left px-4 py-2 hover:bg-blue-200 hover:text-blue-500 rounded-b-lg flex items-center justify-between dark:hover:bg-user-filterHoverBg"
              onMouseEnter={() => setActiveDropdown("readingPhyId")}
            >
              Reading Physician
              {activeDropdown === "readingPhyId" && <CaretRight className="w-4 h-4 stroke-blue-500" />}
            </button>
            {activeDropdown === "readingPhyId" &&
              renderSubDropdown("readingPhyId", availableFilters.readingPhyId)}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterButton;