import { useCallback } from 'react';
import { useExamStore } from '@/store/use-exam-store';
import { EFrameType } from '@/enums/base-enum';
import { ActiveAnotomyReferences, DiagnosticView } from '@/types/exam';

const useSetAnotomyReference = () => {
  const { examData, setActiveAnotomyReferences } = useExamStore();

  const getFirstAvailableFrame = (diagnosticView: DiagnosticView) => {
    if (diagnosticView?.thumbnails?.length > 0) {
      return {
        frame: diagnosticView?.thumbnails[0],
        type: EFrameType.THUMBNAIL,
        index: 0
      };
    }
    if (diagnosticView?.clipboards?.length > 0) {
      return {
        frame: diagnosticView?.clipboards[0],
        type: EFrameType.CLIPBOARD,
        index: 0
      };
    }
    return null;
  };

  return useCallback((anatomyId: number) => {
    const anatomyIndex: number = examData.findIndex(a => a.anatomyId === anatomyId);
    if (anatomyIndex === -1) return;

    const activeAnatomy = examData[anatomyIndex];
    const diagnosticView = activeAnatomy.diagnosticViewData[0];
    if (!diagnosticView) return;

    const frameInfo = getFirstAvailableFrame(diagnosticView);
    if (!frameInfo) return;

    const newReference: ActiveAnotomyReferences = {
      examId: activeAnatomy.examId,
      anatomyId,
      diagnosticViewId: diagnosticView.diagnosticViewId,
      frameId: frameInfo.frame.frameId,
      frameType: frameInfo.type,
      referenceIndexes: {
        anatomyIndex,
        diagnosticViewIndex: 0,
        frameIndex: frameInfo.index
      },
      frameReference: {
        currentFrameBase64: frameInfo.frame.frameBase64,
        currentFrameIndex: frameInfo.index,
        thumbnailId: frameInfo.frame.thumbnailId
      }
    };

    setActiveAnotomyReferences(newReference);
  }, [examData, setActiveAnotomyReferences]);
};

export default useSetAnotomyReference;

