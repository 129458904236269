import React, { useCallback, memo, useState, useEffect, useMemo } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import Template from "@/components/svg/template";
import { ReportTemplateCellProps, UpdateTemplateRequest, UpdateTemplateResponse, ReportTemplate } from "@/types/dashboard";
import { Status } from "@/enums/dashboard-enum";
import { API_LIST } from "@/utlity/api-list";
import { apiService } from "@/utlity/api-service";
import { CustomAxiosResponse } from '@/types';

const ReportTemplateCell: React.FC<ReportTemplateCellProps & { templates: ReportTemplate[] }> = memo(({
  reportTemplate,
  id,
  selectedTemplates,
  onTemplateChange,
  status,
  templates
}) => {
  const [localValue, setLocalValue] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (reportTemplate) {
      const template = templates.find(t => t.name === reportTemplate);
      if (template) {
        setLocalValue(template.id.toString());
      }
    } else if (selectedTemplates[id]) {
      const template = templates.find(t => t.name === selectedTemplates[id]);
      if (template) {
        setLocalValue(template.id.toString());
      }
    }
  }, [reportTemplate, selectedTemplates, id, templates]);

  const displayValue: string = useMemo(() => {
    if (reportTemplate) return reportTemplate;
    if (!localValue) return "";
    const template = templates.find(t => t.id.toString() === localValue);
    return template?.name ?? "";
  }, [reportTemplate, localValue, templates]);

  const updateReportTemplate = useCallback(async (templateId: string): Promise<boolean> => {
    try {
      setIsUpdating(true);
      const numericTemplateId = parseInt(templateId, 10);
      
      if (isNaN(numericTemplateId)) {
        throw new Error("Invalid template ID");
      }

      const requestData: UpdateTemplateRequest = {
        examId: Number(id),
        reportTemplateId: numericTemplateId
      };
      
      const response: CustomAxiosResponse<UpdateTemplateResponse> = await apiService.post(
        API_LIST.UPDATE_REPORT_TEMPLATE,
        requestData
      );

      if (response.status === 200) {
        const template = templates.find(t => t.id.toString() === templateId);
        if (template) {
          onTemplateChange(id, template.name);
        }
        return true;
      }
      return false;
    } catch (error) {
      const template = templates.find(t => t.name === selectedTemplates[id]);
      setLocalValue(template?.id.toString() ?? "");
      onTemplateChange(id, "");   
      return false;
    } finally {
      setIsUpdating(false);
    }
  }, [id, onTemplateChange, selectedTemplates]);

  const handleValueChange = useCallback(async (value: string) => {
    const template = templates.find(t => t.id.toString() === value);
    
    if (template) {
      setLocalValue(value);
      const success: boolean = await updateReportTemplate(value);
      if (!success) {
        const currentTemplate = templates.find(t => t.name === selectedTemplates[id]);
        setLocalValue(currentTemplate?.id.toString() ?? "");
        onTemplateChange(id, "");
      }
    }
  }, [updateReportTemplate, id, onTemplateChange, templates, selectedTemplates]);

  const isDisabled: boolean = useMemo(() => 
    status === Status.Scheduled || isUpdating, 
    [status, isUpdating]
  );

  const selectItems: React.ReactNode[] = useMemo(() => (
    templates.map((template) => (
      <SelectItem
        key={template.id}
        value={template.id.toString()}
        className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer"
      >
        <div className="flex">
          <Template stroke="#5C5F66" className="w-4 h-4 flex-shrink-0 line-clamp-1"/>
          <span className="pl-2 text-xs font-normal text-builder-lightText">
            {template.name}
          </span>
        </div>
      </SelectItem>
    ))
  ), [templates]);

  if (displayValue) {
    return (
      <div className="text-left text-builder-darkText">
        {displayValue}
      </div>
    );
  }

  return (
    <div>
      <Select
        value={localValue}
        onValueChange={handleValueChange}
        disabled={isDisabled}
      >
        <SelectTrigger 
          className={`w-full truncate h-7 bg-builder-background border border-builder-defaultBorder flex items-center text-xs ${
            isDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <SelectValue 
            placeholder="Choose a template" 
            className="text-xs font-normal text-builder-lightText w-full truncate pr-2"
          />
        </SelectTrigger>
        <SelectContent 
          className="bg-builder-background text-builder-darkText border border-builder-defaultBorder rounded-md max-h-[200px] overflow-y-auto z-50"
          style={{ 
            width: 'var(--radix-select-trigger-width)',
            minWidth: '200px'
          }}
        >
          {selectItems}
        </SelectContent>
      </Select>
    </div>
  );
});

ReportTemplateCell.displayName = 'ReportTemplateCell';

export default ReportTemplateCell;