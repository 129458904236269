import React, { useState, useEffect } from 'react';
import { Input } from "@/components/ui/input";
import Search from "@/components/svg/MagnifyingGlass";
import { SearchInputProps } from "@/types/dashboard";
import { cn } from '@/lib/utils';

const SearchInput: React.FC<SearchInputProps> = ({ className, searchQuery, onSearch }) => {
  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  // Custom debounce implementation with trimming
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalSearchQuery(value);

    // Clear existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set new timeout
    const newTimeout = setTimeout(() => {
      // Allow spaces in middle but trim start/end
      const processedValue = value.trim();
      if (processedValue !== value) {
        setLocalSearchQuery(processedValue);
      }
      onSearch(processedValue);
    }, 1000);

    setTypingTimeout(newTimeout);
  };

  // Clear search on Escape key press
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setLocalSearchQuery('');
      onSearch('');
    }
  };

  // Handle search submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const processedValue = localSearchQuery.trim();
    if (processedValue !== localSearchQuery) {
      setLocalSearchQuery(processedValue);
    }
    onSearch(processedValue);
  };

  // Sync local state with prop
  useEffect(() => {
    setLocalSearchQuery(searchQuery);
  }, [searchQuery]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  return (
    <form onSubmit={handleSubmit} className={"relative w-full sm:max-w-sm" + cn(className)}>
      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 stroke-builder-lightText" />
      <Input
        type="search"
        placeholder="Search"
        value={localSearchQuery}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        className="border border-builder-defaultBorder p-1 pl-10 rounded-lg w-full font-normal tracking-wide placeholder:italic focus-visible:ring-offset-0 focus-visible:ring-0 text-dropdown-primaryText text-base"
        aria-label="Search"
        autoComplete="off"
        minLength={1}
        maxLength={100}
      />
    </form>
  );
};

export default SearchInput;
