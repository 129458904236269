import momentTimezone from "moment-timezone";
import { ETotalFrames } from "@/enums/base-enum";

export const momentFormat = (time: string, formatString: string = "h:mm A"): string => {
  const currentUserTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return momentTimezone.utc(time).tz(currentUserTimeZone).format(formatString);
};

export const calculateCurrentTime = (
  startFrame: number,
  endFrame: number,
  currentFrame: number,
): string => {
  if (currentFrame < startFrame || currentFrame > endFrame) {
    return "Invalid frame index";
  }

  // Calculate elapsed frames and convert to seconds
  const elapsedFrames = currentFrame - startFrame;
  const elapsedSeconds = Math.floor(elapsedFrames / ETotalFrames.FRAMES);

  // Convert seconds to hh:mm:ss format
  const hours = Math.floor(elapsedSeconds / 3600);
  const minutes = Math.floor((elapsedSeconds % 3600) / 60);
  const seconds = elapsedSeconds % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};