"use client";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import FilterButton from "@/components/dashboard/FilterButton";
import {
  type ColumnDef,
  type SortingState,
  type ColumnFiltersState,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import type { Patient } from "@/types/Patient";
import TaskCard from "@/components/dashboard/TaskCard";
import SearchInput from "@/components/dashboard/SearchInput";
import PatientTable from "@/components/dashboard/PatientTable";
import { ColumnConfig, EExamStatusId, ExamListItem, Filters, ReportTemplate, Status, StatusCounts } from "@/types/dashboard";
import SortableHeader from '@/components/dashboard/SortableHeader';
import ReportTemplateCell from '@/components/dashboard/ReportTemplateCell';
import StatusCell from '@/components/dashboard/StatusCell';
import ActionCell from '@/components/dashboard/ActionCell';
import { momentFormat } from "@/utlity/format";
import { API_LIST } from "@/utlity/api-list";
import { apiService } from "@/utlity/api-service";
import { useRouter } from "next/navigation";
import { useExamStore } from "@/store/use-exam-store";
import { DataItem } from "@/types/exam";
import useSetAnotomyReference from '@/hooks/use-set-anotomy-reference';
import { PatientDetails } from "@/types/examDetails";
import { statusColors } from "@/enums/dashboard-enum";
import { useAnnotationHistoryStore } from "@/store/use-annotation-history-store";

const DEFAULT_PAGE_SIZE = 10;

const makeColumns = ({
  selectedTemplates = {},
  getStatusClasses = () => "",
  setSelectedTemplates,
  handleActionClick,
  templates = []
}: Partial<ColumnConfig> & { templates?: ReportTemplate[] }): ColumnDef<Patient>[] => [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <SortableHeader
        title="PATIENT NAME"
        onSort={(desc) => column.toggleSorting(desc)}
        className="pl-2"
      />
    ),
    size: 100,
    cell: ({ row }) => (
      <div className="pl-3">
          <p className="text-builder-darkText font-normal">{row.original.name}</p>
      </div>
    ),
  },
  {
    accessorKey: "patientId",
    header: "PATIENT ID",
    size: 100,
  },
  {
    accessorKey: "accessionNo",
    header: "ACCESSION NO",
    size: 100,
  },
  {
    accessorKey: "scheduledDateTime",
    header: ({ column }) => (
      <SortableHeader
        title="SCHEDULED DATE & TIME"
        onSort={(desc) => column.toggleSorting(desc)}
      />
    ),
    sortingFn: (rowA, rowB) => new Date(rowA.original.scheduledDateTime).getTime() - new Date(rowB.original.scheduledDateTime).getTime(),
    size: 150,
  },
  {
    accessorKey: "examType",
    header: "EXAM TYPE",
    size: 80,
  },
  {
    accessorKey: "reportTemplate",
    header: "REPORT TEMPLATE",
    size: 100,
    cell: ({ row }) => (
      <ReportTemplateCell
        id={row.original.id}
        reportTemplate={row.original.reportTemplate}
        selectedTemplates={selectedTemplates}
        onTemplateChange={(id, value) => {
          if (setSelectedTemplates) {
            setSelectedTemplates(prev => ({ ...prev, [id]: value }));
          }
        }}
        status={row.original.status as Status}
        templates={templates}
      />
    ),
  },
  {
    accessorKey: "readingPhysician",
    header: ({ column }) => (
      <div className="whitespace-nowrap">READING PHYSICIAN</div>
    ),
    size: 100,
    cell: ({ row }) => (
      <div className="capitalize">
        {row.original.readingPhysician}
      </div>
    ),
  },
  {
    accessorKey: "status",
    header: "STATUS",
    size: 100,
    cell: ({ row }) => (
      <StatusCell
        status={row.original.status}
        getStatusClasses={getStatusClasses}
      />
    ),
  },
  {
    accessorKey: "action",
    header: "ACTION",
    size: 100,
    cell: ({ row }) => (
      <ActionCell 
        action={row.original.action} 
        status={row.original.status as Status} 
        statusId={row.original.statusId}
        id={row.original.id} 
        onClick={handleActionClick!}
      />
    ),
  },
];

// Extracted utility functions
const getGreeting = (hour: number): string => {
  if (hour >= 5 && hour < 12) return "Good Morning";
  if (hour >= 12 && hour < 17) return "Good Afternoon";
  return "Good Evening";
};

const getUserNameFromStorage = (): string => {
  const userData = localStorage.getItem("user_data");
  if (!userData) return "";
  const user = JSON.parse(userData);
  return `${user?.firstName || ""} ${user?.lastName || ""}`.trim();
};

const processMeasurements = (examResponse: DataItem[]): Record<string, any[]> => {
  const measurementsByImage: Record<string, any[]> = {};
  
  examResponse?.forEach(anatomy => {
    anatomy.diagnosticViewData.forEach(diagnosticView => {
      [...(diagnosticView.thumbnails || []), ...(diagnosticView.clipboards || [])].forEach(item => {
        if (item.measurements?.length) {
          measurementsByImage[item.thumbnailId.toString()] = item.measurements;
        }
      });
    });
  });

  return measurementsByImage;
};

interface ExamDetailsServiceParams {
  examId: number;
  setExamData: (data: DataItem[]) => void;
  setAnotomyRef: (id: number) => void;
  setImageAnnotations: (data: Record<string, any[]>) => void;
  clearAllHistory: () => void;
}

const fetchExamDetailsService = async ({
  examId,
  setExamData,
  setAnotomyRef,
  setImageAnnotations,
  clearAllHistory
}: ExamDetailsServiceParams): Promise<void> => {
  try {
    const reportResponse = await apiService.get<any>(`${API_LIST.GET_EXAM_DETAILS}/${examId}`);
    const examResponse: DataItem[] = reportResponse.data;
    
    setExamData(examResponse);
    clearAllHistory();
    
    const firstAnatomyId: number = examResponse?.[0]?.anatomyId;
    setAnotomyRef(firstAnatomyId);

    const measurementsByImage = processMeasurements(examResponse);
    setImageAnnotations(measurementsByImage);
  } catch (error) {
    console.error("Error fetching exam details:", error);
  }
};

const fetchPatientDetailsService = async (
  examId: number,
  setPatientDetails: (data: any) => void
): Promise<void> => {
  try {
    const response = await apiService.get<any>(`${API_LIST.GET_PATIENT_DETAILS_BY_EXAM_ID}/${examId}`);
    localStorage.setItem('patientName', (response?.data as PatientDetails)?.patientName || '');
    setPatientDetails(response?.data);
  } catch (error) {
    console.error("Error fetching patient details:", error);
  }
};

interface ActionClickParams {
  id: number;
  status?: Status;
  statusId?: EExamStatusId;
}

const Dashboard = () => {
  const router = useRouter();
  const { setExamData, setPatientDetails, setExamDetails  } = useExamStore();
  const { setImageAnnotations, clearAllHistory } = useAnnotationHistoryStore();
  const setAnotomyRef = useSetAnotomyReference();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<Record<string, string>>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [patients, setPatients] = useState<Patient[]>([]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [totalDBRecords, setTotalDBRecords] = useState(0);
  const [loadedRecordsCount, setLoadedRecordsCount] = useState(0);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [totalCount, setTotalCount] = useState(0);
  const [isLoading] = useState(false);

  const [filters, setFilters] = useState<Filters>({
    date: [],
    examType: [],
    readingPhyId: []
  });

  const [statusCounts, setStatusCounts] = useState<StatusCounts>({});

  const [selectedStatus, setSelectedStatus] = useState<string>('All Studies');
  const [selectedLabelId, setSelectedLabelId] = useState<string>('');

  const [userName, setUserName] = useState("");
  const [greeting, setGreeting] = useState("");

  const [allPatients, setAllPatients] = useState<Patient[]>([]);

  useEffect(() => {
    // Get user data from localStorage
    const userData = localStorage.getItem("user_data");
    if (userData) {
      const user = JSON.parse(userData);
      const fullName = `${user?.firstName || ""} ${user?.lastName || ""}`.trim();
      setUserName(fullName);
    }

    // Set greeting based on time of day
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      setGreeting("Good Morning");
    } else if (hour >= 12 && hour < 17) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, []);

  const fetchData = useCallback(async (offSet: number = 0, pageSize: number = DEFAULT_PAGE_SIZE) => {
    setIsFetching(true);
    try {
      const currentSorting = sorting.length > 0
        ? { field: sorting[0].id, order: sorting[0].desc ? 1 : 0 }
        : { field: "scheduledDateTime", order: 0 };

      const payload = {
        offSet,
        limit: pageSize,
        searchInput: searchQuery,
        sortField: currentSorting.field,
        sortOrder: currentSorting.order,
        labelId: selectedLabelId,
        additionalFilter: {
          date: filters.date,
          examType: filters.examType,
          readingPhyId: filters.readingPhyId
        }
      };

      const response = await apiService.post<any>(API_LIST.GET_EXAM_USER_BY_ID, payload);
      const { totalRecords, statusCounts, listData } = response.data;

      const mappedPatients = listData.map((item: ExamListItem) => ({
        id: item.id,
        name: item.name,
        patientId: item.patientId,
        accessionNo: item.accessionNo,
        scheduledDateTime: momentFormat(item.scheduledDateTime, 'MM/DD/YYYY, hh:mm A'),
        examType: item.examType,
        reportTemplate: item.reportTemplateName,
        readingPhysician: item.readingPhysician,
        status: item.status,
        statusId: item.statusId,
        action: "View",
        examTypeID: item.examTypeID,
        readingPhysicianID: item.readingPhysicianID,
        clipboardImages: item.clipboardImages,
        totalImages: item.totalImages
      }));

      if (offSet === 0) {
        setPatients(mappedPatients);
        setAllPatients(prev => [...prev, ...mappedPatients]);
      } else {
        setPatients(prev => [...prev, ...mappedPatients]);
        setAllPatients(prev => [...prev, ...mappedPatients]);
      }
      
      setTotalDBRecords(totalRecords);
      setLoadedRecordsCount(prev => offSet === 0 ? listData.length : prev + listData.length);
      setTotalCount(totalRecords);
      setStatusCounts(statusCounts || {});
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsFetching(false);
    }
  }, [searchQuery, sorting, filters, selectedLabelId]);

  useEffect(() => {
    const userName = getUserNameFromStorage();
    setUserName(userName);
    setGreeting(getGreeting(new Date().getHours()));
  }, []);

  useEffect(() => {
    setCurrentOffset(0);
    fetchData(0, DEFAULT_PAGE_SIZE);
  }, [fetchData]);

  const handleSearch = useCallback((value: string) => {
    setSearchQuery(value);
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
  }, []);

  const handleFilterChange = useCallback((newFilters: Filters) => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    setFilters({
      date: newFilters.date || [],
      examType: newFilters.examType || [],
      readingPhyId: newFilters.readingPhyId || []
    });
  }, []);

  const handleStatusFilter = useCallback((status: string, labelId: string) => {
    setSelectedStatus(status);
    setSelectedLabelId(labelId);
    // Set column filters based on status
    if (status === "All Studies" ) {
      setColumnFilters([]);
    } else {
      setColumnFilters([{ 
        id: 'status', 
        value: status
      }]);
    }
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    setCurrentOffset(0);
  }, []);

  const handleBottomReached = useCallback(() => {    
    if (isFetching) return;
    
    const nextOffset = currentOffset + DEFAULT_PAGE_SIZE;
    if (nextOffset < totalDBRecords && loadedRecordsCount <= (totalDBRecords)) {
      setCurrentOffset(nextOffset);
      fetchData(nextOffset, DEFAULT_PAGE_SIZE);
    }
  }, [currentOffset, loadedRecordsCount, totalDBRecords, isFetching, fetchData]);

  const tasks = useMemo(() => {
    return [
      {
        count: totalDBRecords,
        label: "All Studies",
        color: "bg-status-allStudies",
        fontColor: "text-card-unfilled",
      },
      {
        count: statusCounts?.Scheduled ?? 0,
        label: "Scheduled",
        color: statusColors[Status.Scheduled],
        fontColor: "text-status-text-scheduled",
      },
      {
        count: statusCounts?.Preliminary ?? 0,
        label: "Preliminary",
        color: statusColors[Status.Preliminary],
        fontColor: "text-status-text-preliminary",
      },
      {
        count: statusCounts?.["In Review"] ?? 0,
        label: "In Review",
        color: statusColors[Status.InReview],
        fontColor: "text-status-text-inReview",
      },
      {
        count: statusCounts?.Revise ?? 0,
        label: "Revise",
        color: statusColors[Status.Revise],
        fontColor: "text-status-text-revise",
      },
      {
        count: statusCounts?.Finalized ?? 0,
        label: "Finalized",
        color: statusColors[Status.Finalized],
        fontColor: "text-status-text-completed",
      },
    ];
  }, [totalDBRecords, statusCounts]);

  const getStatusClasses = useCallback((status: string): string => {
    const statusClassMap = {
      [Status.Scheduled]: "bg-status-scheduled text-status-text-scheduled",
      [Status.InReview]: "bg-status-inReview text-status-text-inReview",
      [Status.Revise]: "bg-status-revised text-status-text-revise",
      [Status.Preliminary]: "bg-status-preliminary text-status-text-preliminary",
      [Status.Finalized]: "bg-status-completed text-status-text-completed"
    };
    return statusClassMap[status as Status] || "";
  }, []);

  const handleActionClick = useCallback(({ id, status, statusId }: ActionClickParams) => {
    fetchPatientDetailsService(id, setPatientDetails);
    setExamDetails({
      examId: id,
      status: status as Status,
      statusId: statusId as EExamStatusId
    });

    if ([Status.Scheduled].includes(status as Status)) {
      router.push(`/patient-profile/${id}`);
      return;
    }

    fetchExamDetailsService({
      examId: id,
      setExamData,
      setAnotomyRef,
      setImageAnnotations,
      clearAllHistory
    }).then(() => {
      router.push(`/report-builder/${id}`);
    }).catch(error => {
      console.error("Error in handleActionClick:", error);
    });
  }, [router, setExamData, setAnotomyRef, setImageAnnotations, clearAllHistory, setPatientDetails]);
  
  const columns = useMemo(
    () => makeColumns({
      selectedTemplates,
      getStatusClasses,
      setSelectedTemplates,
      handleActionClick,
      templates: []
    }),
    [selectedTemplates, getStatusClasses, handleActionClick]
  );

  const table = useReactTable({
    data: patients,
    columns,
    pageCount: Math.ceil(totalCount / pagination.pageSize),
    state: {
      sorting,
      globalFilter: searchQuery,
      columnFilters,
      pagination,
    },
    enableFilters: true,
    manualFiltering: false,
    onSortingChange: (updater) => {
      setSorting(updater);
      setCurrentOffset(0); // Reset offset when sorting changes
    },
    onGlobalFilterChange: handleSearch,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  // Update columns after table is initialized
  useEffect(() => {
    table.options.columns = makeColumns({
      selectedTemplates,
      getStatusClasses,
      setSelectedTemplates,
      handleActionClick,
      templates: []
    });
  }, [table, selectedTemplates, getStatusClasses, handleActionClick]);

  // Handle template changes
  const handleTemplateChange = useCallback((id: number, templateName: string) => {
    setSelectedTemplates(prev => ({
      ...prev,
      [id]: templateName
    }));
  }, []);

  return (
    <div className="h-[calc(100vh-92px)] w-full flex flex-row overflow-hidden rounded-lg">
      <div className="h-full w-full overflow-y-auto scrollbar-hidden">
        <section className="space-y-4 p-2">
          <div className="flex flex-col gap-2 py-2">
            <h1 className="text-2xl font-bold dark:text-radio-displayTitle">{greeting}{userName ? `, ${userName}` : ""}!</h1>
            <p className="text-gray-600 font-normal text-dropdown-primaryText">
              Start your day by checking today's tasks and updates.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-4 mt-4">
            {tasks.map(({ label, count, color, fontColor }) => (
              <TaskCard
                key={label}
                label={label}
                count={count}
                color={color}
                fontColor={fontColor}
                isSelected={selectedStatus === label}
                onClick={handleStatusFilter}
              />
            ))}
          </div>
        </section>

        <section className="m-2">
          <div className="flex justify-end gap-2 items-center my-6">
            <SearchInput searchQuery={searchQuery} onSearch={handleSearch} />
            <FilterButton
              patients={patients}
              allPatients={allPatients}
              onFilterChange={handleFilterChange}
              selectedStatus={selectedStatus}
            />
          </div>

          <PatientTable
            table={table}
            isLoading={isLoading}
            onBottomReached={handleBottomReached}
            hasNextPage={loadedRecordsCount < totalDBRecords}
            totalRecords={totalDBRecords}
            isFetching={isFetching}
            handleActionClick={handleActionClick}
            onTemplateChange={handleTemplateChange}
            selectedTemplates={selectedTemplates}
          />
        </section>
      </div>
    </div>
  );
}

export default Dashboard;
